exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-03-ancient-artifacts-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/volodymyr/WEB/GATSBY/AllaGatsby/posts/2019-08-03-ancient-artifacts.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-03-ancient-artifacts-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-04-greece-1-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/volodymyr/WEB/GATSBY/AllaGatsby/posts/2019-08-04-greece-1.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-04-greece-1-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-05-greece-2-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/volodymyr/WEB/GATSBY/AllaGatsby/posts/2019-08-05-greece-2.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-05-greece-2-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-13-rome-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/volodymyr/WEB/GATSBY/AllaGatsby/posts/2019-08-13-Rome.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2019-08-13-rome-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2023-09-11-smak-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/volodymyr/WEB/GATSBY/AllaGatsby/posts/2023-09-11-smak.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-2023-09-11-smak-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-summer-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/volodymyr/WEB/GATSBY/AllaGatsby/posts/summer.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-posts-summer-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-portfolio-item-mdx-js": () => import("./../../../src/templates/portfolioItemMdx.js" /* webpackChunkName: "component---src-templates-portfolio-item-mdx-js" */)
}

